import { PrivyProvider as PrivyProviderBase } from "@privy-io/react-auth";
import { useRouter } from "next/navigation";
import { base, baseGoerli, baseSepolia } from "viem/chains";

export function PrivyProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  const handleLogin = async (user: any) => {
    console.log("User logged in:", user);

    const accountData = {
      privyUserId: user.id,
      wallet: user.wallet?.address,
      twitter: {
        name: user.twitter?.name ?? "",
        img: user.twitter?.profilePictureUrl ?? "",
        handle: user.twitter?.username ?? "",
        id: user.twitter?.subject,
      },
    };

    try {
      const response = await fetch("/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(accountData),
      });

      if (response.status === 200) {
        console.log("User information saved successfully.");
      } else if (response.status === 400) {
        console.log("User already exists.");
      } else {
        console.error("Failed to save user information.");
      }
    } catch (error) {
      console.error("Error saving user information:", error);
    }
  };

  return (
    <PrivyProviderBase
      appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID!}
      config={{
        defaultChain: base,
        supportedChains: [base, baseSepolia, baseGoerli],
      }}
      onSuccess={handleLogin}
    >
      {children}
    </PrivyProviderBase>
  );
}

"use client";

import React, { useEffect, useState } from "react";
import { SessionProvider } from "next-auth/react";
import { PrivyProvider } from "../components/Auth/PrivyProvider";

export function Providers({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return (
    <SessionProvider>
      <PrivyProvider>{mounted && children}</PrivyProvider>
      <div id="modal-root" />
    </SessionProvider>
  );
}

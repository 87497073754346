"use client";

import React, { useEffect, useState } from "react";

let deferredPrompt: any = null;

export default function PWAInstallPrompt() {
  const [isInstallable, setIsInstallable] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const isDismissedStored = localStorage.getItem("pwa-prompt-dismissed");
    if (isDismissedStored) {
      setIsDismissed(true);
    }

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      deferredPrompt = e;
      setIsInstallable(true);
    };

    const handleAppInstalled = () => {
      deferredPrompt = null;
      setIsInstallable(false);
      setIsDismissed(true);
      localStorage.setItem("pwa-prompt-dismissed", "true");
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      window.removeEventListener("appinstalled", handleAppInstalled);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    try {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        deferredPrompt = null;
        setIsInstallable(false);
      }
    } catch (error) {
      console.error("Error during installation:", error);
    }
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    localStorage.setItem("pwa-prompt-dismissed", "true");
  };

  if (!isInstallable || isDismissed) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 bg-[#120000] border border-[#EACDD2]/20 rounded-xl shadow-lg p-4 flex items-center justify-between z-50 backdrop-blur-sm">
      <div className="flex-1">
        <h3 className="font-bold text-lg text-[#EACDD2]">Install Aura</h3>
        <p className="text-sm text-[#EACDD2]/60">Add to your home screen for quick access</p>
      </div>

      <div className="flex items-center gap-2">
        <button
          onClick={handleInstallClick}
          className="bg-[#EACDD2] text-[#120000] px-4 py-2 rounded-lg hover:bg-opacity-90 transition-all"
        >
          Install
        </button>

        <button
          onClick={handleDismiss}
          className="p-2 text-[#EACDD2]/60 hover:text-[#EACDD2] transition-all w-8 h-8 flex items-center justify-center"
          aria-label="Close"
        >
          ✕
        </button>
      </div>
    </div>
  );
}
